import * as React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { StaticImage } from 'gatsby-plugin-image';

export function UseCaseSelector() {
  // Number of thumbnails to display at once.
  const displayCount = 4;

  // The actual use cases and their order is defined here. The thumbnail name
  // must correspond with a query name in the GraphQL query that follows.
  const extraImgProps = {
    alt: '',
    css: { display: 'block !important' },
    objectFit: 'contain',
    style: {
      borderWidth: '0',
      borderColor: 'white',
      borderStyle: 'solid',
      borderRadius: '3ex',
    },
    imgStyle: {
      borderWidth: '0.75ex',
      borderColor: 'white',
      borderStyle: 'solid',
      borderRadius: '3ex',
    },
  };
  const useCases = [
    {
      name: 'Large-scale 3-D simulations',
      link: '/applications#3d-simulations',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_large_scale_3d.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Nondestructive Evaluation',
      link: '/applications/nondestructive_evaluation',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_nondestructive_evaluation.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Borehole acoustics',
      link: '/applications#borehole-acoustics',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_borehole_acoustics.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Full-waveform inversion',
      link: '/applications#full-waveform-inversion',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_full_waveform_inversion.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Pipeline monitoring',
      link: '/applications#pipeline-monitoring',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_pipeline_monitoring.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Extra-planetary imaging',
      link: '/applications#extra-planetary-imaging',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_extra_planetary.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Seismology',
      link: '/applications#seismology',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_global_seismology.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
    {
      name: 'Medical Imaging',
      link: '/applications#medical-imaging',
      image: (
        <StaticImage
          src="../images/applications/thumbnails/thumb_medical_imaging.png"
          placeholder="tracedSVG"
          {...extraImgProps}
        />
      ),
    },
  ];

  const [currentOffset, setCurrentOffset] = React.useState(0);

  return (
    <>
      <Grid style={{ marginLeft: '0px', marginRight: '0px' }} columns="equal">
        <Grid.Column key="0" verticalAlign="middle" textAlign="right">
          {currentOffset > 0 && (
            <Button
              circular
              icon="arrow left"
              onClick={() => setCurrentOffset(Math.max(currentOffset - 1, 0))}
            />
          )}
        </Grid.Column>
        <Grid.Column
          key={displayCount + 1}
          verticalAlign="middle"
          textAlign="left"
        >
          {currentOffset < useCases.length - displayCount && (
            <Button
              circular
              icon="arrow right"
              onClick={() =>
                setCurrentOffset(
                  Math.min(currentOffset + 1, useCases.length - displayCount)
                )
              }
            />
          )}
        </Grid.Column>
      </Grid>
      <Grid style={{ marginLeft: '0px', marginRight: '0px' }} columns="equal">
        <Grid.Column>
          <Grid doubling columns={4}>
            {useCases
              .filter(
                (_, index) =>
                  index >= currentOffset && index < currentOffset + displayCount
              )
              .map((i, index) => (
                <Grid.Column
                  // @ts-ignore
                  width={Math.floor(16 / displayCount).toString()}
                  key={index}
                  textAlign="center"
                >
                  <a href={i.link} css={{}}>
                    <div>
                      <div
                        css={{
                          borderRadius: '3ex',
                          backgroundColor: '#ffffff88',
                          transition: 'transform .2s',
                          '&:hover': {
                            boxShadow: '0px 0px 11px 1px rgba(0,0,0,0.57)',
                            transform: 'scale(1.05) rotate(1.5deg)',
                          },
                        }}
                      >
                        {i.image}
                      </div>
                      <div
                        style={{
                          fontSize: '90%',
                          color: 'white',
                        }}
                      >
                        {i.name}
                      </div>
                    </div>
                  </a>
                </Grid.Column>
              ))}
          </Grid>
        </Grid.Column>
      </Grid>
    </>
  );
}
