import * as React from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';

function isBrowser() {
  return typeof window !== 'undefined';
}

function getValue(key: string, defaultValue: boolean) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key: string, value: any) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue: boolean, key: string) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookieConsent = () => {
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden',
  );

  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true; path=/';
    setBannerHidden(true);
  };

  const DisableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=false; path=/';
    setBannerHidden(true);
  };

  return (
    <>
      {!bannerHidden && (
        <div className="ui segment cookie-banner" style={
          {
            boxShadow: "1px 1px 7px",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            left: "20px",
            maxWidth: "600px",
            zIndex: 1000
          }
        }>
          <span>
            We use cookies to analyze our traffic, but won't track you until
            explicit consent is given. Using our contact form will create
            relevant cookies and transmit data to third party services to keep
            track of your request. Read more about how we use personal
            information in our <Link to="/privacy_policy">Privacy Policy</Link>.
          </span>
          <div style={{ marginTop: '10px' }}>
            <Button className="ui purple button" onClick={EnableAnalytics}>
              All cookies
            </Button>
            <Button className="ui violet button" onClick={DisableAnalytics} style={{ marginTop: '10px' }}>
              Essential cookies only
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
export default CookieConsent;